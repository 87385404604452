import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../App";
import FixedBottom from "../../guestPage/FixedBottom";
import FooterText from "../../guestPage/FooterText";
import TopNavigation from "../../Shared/TopNavigation/TopNavigation";
import "./Home.css";

const Home = () => {
    const [globalState, setGlobalState] = useContext(UserContext);
    const { userInfo } =
        globalState;
    return (
        <div className="container-area" style={{ height: '100vh' }}>
            <TopNavigation />
            <div className="container-fluid  h-100 d-flex justify-content-center align-items-center flex-column">
                <div className="text-center form-box p-3 rounded">
                    <h1>বঙ্গবন্ধু ঐক্য যুব ও ছাত্র সংগঠন </h1>
                    <p>এর পক্ষ থেকে ১ম বার </p>
                    <h3 className="text-success mb-3">পিকনিক ও ব্যান্ড শো</h3>

                    {!userInfo?.uid && <Link to="/login" className="btn bg-theme text-white btn-lg rounded">
                        <FontAwesomeIcon icon={faUsers} /> Guest Manager
                    </Link>}
                </div>

            </div>
            <FixedBottom>
                <FooterText text="Developed by: Appsmaker BD" />
            </FixedBottom>
        </div>
    );
};

export default Home;
