import { faHome, faUnlock, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserContext } from "../../../App";
import { handleSignOut, initializeLoginFramework } from "../Login/LoginManager";

import "./TopNavigation.css";

const TopNavigation = () => {
    initializeLoginFramework();
    //Sign Out
    const signOut = () => {
        handleSignOut().then((res) => {
            //setUserInfo(res);
            setGlobalState(res);
            //history.replace(from);
        });
    };

    //Importing UserContext variable  from App.js  into useContext() hook here
    const [globalState, setGlobalState] = useContext(UserContext);
    let photo = "https://i.ibb.co/jJW1p1K/user.png";
    if (globalState?.userInfo?.photo) {
        photo = globalState?.userInfo?.photo;
    }

    return (
        <header>
            <Navbar fixed="top" expand="lg" className="bg-theme">
                <Container>
                    <Navbar.Brand>
                        <Link to="/">OPEN DooR | Guest Manager</Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse
                        id="basic-navbar-nav"
                        className="justify-content-end"
                    >
                        <Nav className="custom-link">
                            <Link
                                className="nav-link px-3"
                                to="/"
                                spy={true}
                                smooth={true}
                            >
                                <FontAwesomeIcon icon={faHome} /> Home
                            </Link>


                            {globalState?.userInfo?.email && (
                                <>
                                    <Link className="nav-link px-3" to="/guests"><FontAwesomeIcon icon={faUsers} /> Manage Guests</Link>

                                    <button onClick={signOut} className="btn btn-dark mx-3">
                                        <FontAwesomeIcon icon={faUnlock} /> Sign out
                                    </button>
                                </>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default TopNavigation;
