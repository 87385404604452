import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../App";
import { handleSignOut } from "../../Shared/Login/LoginManager";
import "./SideNav.css";

const SideNav = () => {
    const [globalState, setGlobalState] = useContext(UserContext);
    const [isAdmin, setIsAdmin] = useState(false);

    let photo = "https://i.ibb.co/jJW1p1K/user.png";
    if (globalState?.userInfo?.photo) {
        photo = globalState?.userInfo?.photo;
    }

    //Sign Out
    const signOut = () => {
        handleSignOut().then((res) => {
            //setUserInfo(res);
            setGlobalState(res);
            //history.replace(from);
        });
    };

    useEffect(() => {
        const url = `https://fathomless-ravine-82400.herokuapp.com/isAdmin`;
        fetch(url, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify({ email: globalState?.userInfo?.email }),
        })
            .then((res) => res.json())
            .then((data) => {
                const newGlobalState = { ...globalState };
                newGlobalState["userInfo"]["isAdmin"] = data;
                setGlobalState(newGlobalState);
                setIsAdmin(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <div className="sideNavigation col-md-2 shadow p-3 mb-5">
            <h4 className="text-white text-center bg-theme mb-0 ">Apps Maker BD</h4>
            <div
                className="text-left p-2 d-flex flex-justify align-middle"
                style={{ background: "rgba(0,0,0,0.05)" }}
            >
                <div className="avatar" style={{ float: "none", marginTop: "0" }}>
                    <img src={photo} alt="user avatar" />
                </div>
                <p>
                    Hi! {globalState?.userInfo?.name} <br />
                    {isAdmin && <strong>Your role is Admin</strong>}
                </p>
            </div>
            <ul>
                {isAdmin ? (
                    <>
                        <li>
                            <Link className="text-secondary" to="/dashboard">

                                <span>Dashboard</span>
                            </Link>
                        </li>
                        <li>
                            <Link className="text-secondary" to="/dashboard/orderList">
                                <span>Order List</span>
                            </Link>
                        </li>

                        <li>
                            <Link className="text-secondary" to="/dashboard/addWebService">
                                <span>Add Web Service</span>
                            </Link>
                        </li>
                        <li>
                            <Link className="text-secondary" to="/dashboard/addPortfolio">
                                <span>Add Portfolio</span>
                            </Link>
                        </li>
                        <li>
                            <Link className="text-secondary" to="/dashboard/makeAdmin">
                                <span>Make Admin</span>
                            </Link>
                        </li>
                        <li>
                            <Link className="text-secondary" to="/dashboard/manageServices">
                                <span>Manage Services</span>
                            </Link>
                        </li>
                    </>
                ) : (
                    <>
                        <li>
                            <Link className="text-secondary" to="/home">
                                <span>Purchase Website</span>
                            </Link>
                        </li>
                        <li>
                            <Link className="text-secondary" to="/dashboard/orderList">
                                <span>Order List</span>
                            </Link>
                        </li>

                        <li>
                            <Link className="text-secondary" to="/dashboard/addReview">
                                <span>Give Review</span>
                            </Link>
                        </li>
                    </>
                )}

                <li>
                    <Link className="text-secondary" to="" onClick={signOut}>
                        <span>Logout</span>
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default SideNav;
