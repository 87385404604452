import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
const GuestTr = ({ item }) => {
    const { _id, sl, name, phone, address, amount, createdAt } = item;
    const formattedDate = moment(createdAt).format("DD/MM/YYYY  hh:mm:ss A");
    // let qrValue = `http://open-door.appsmakerbd.com/${_id}\nName : ${name}\nPhone: ${phone}\nAddress: ${address}\Created At: ${formattedDate}`
    let qrValue = `http://open-door.appsmakerbd.com/guest/${_id}`;
    return (
        <tr>
            <td className="align-middle">{sl}</td>
            <td className="text-left text-capitalize align-middle">{name}</td>
            <td className="text-left align-middle">{phone}</td>
            <td className="text-left align-middle">{address}</td>
            <td className="text-left align-middle">BDT. <b>{amount ? amount.toFixed(2) : '0.00'}</b></td>
            <td className="text-left align-middle">{formattedDate}</td>
            <td className="text-center align-middle">
                <div style={{ height: "auto", margin: "0 auto", width: "200px" }}>
                    <QRCode
                        size={200}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={qrValue}
                        viewBox={`0 0 256 256`}
                    />
                </div>
            </td>
            <td className="align-middle">
                <Link to={`/guest/${_id}`} className="btn btn-success cursor-pointer">
                    <FontAwesomeIcon icon={faEye} />
                </Link>
            </td>
        </tr>
    );
};

export default GuestTr;
