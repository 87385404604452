import React from "react";
import QRCode from "react-qr-code";
import FooterText from "./FooterText";

const ComponentToPrint = React.forwardRef((props, ref) => {
    const { _id, name, address, createdAt, amount, phone, qrValue, formattedDate } =
        props.item;

    return (
        <div ref={ref} className="mt-5 text-center">
            <h3>Name: {name}</h3>

            <p><b>Phone: {phone}</b></p>
            <p>Address: {address}</p>
            <h4>Amount Received: BDT. <b>{amount ? amount.toFixed(2) : '0.00'}</b></h4>
            <p>Created At: {formattedDate}</p>
            <h3>বঙ্গবন্ধু ঐক্য যুব ও ছাত্র সংগঠন</h3>
            <div
                className="my-4"
                style={{ height: "auto", margin: "0 auto", width: "200px" }}
            >
                <QRCode
                    size={250}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={qrValue}
                    viewBox={`0 0 256 256`}
                />
            </div>
            <p className="text-center">
                <strong>Varification Link:</strong>
                {`http://open-door.appsmakerbd.com/guest/${_id}`}
            </p>
            <FooterText text="Developed by: www.facebook.com/appsmakerbd" />
        </div>
    );
});

export default ComponentToPrint;
