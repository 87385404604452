import React from "react";
import { Table } from "react-bootstrap";
import FooterText from "./FooterText";
import GuestTr from "./GuestTr";

const ComponentToPrintTable = React.forwardRef((props, ref) => {
    const { allGuest } = props;

    return (
        <div ref={ref} className="mt-5   text-center">
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Full Name</th>
                        <th>Phone</th>
                        <th>Address</th>
                        <th>Amount</th>
                        <th>Created Date</th>
                        <th>QR Code</th>
                        <th>Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {allGuest.map((item, index) => (
                        <GuestTr key={index} item={{ ...item, sl: index + 1 }} />
                    ))}
                </tbody>
            </Table>
            <FooterText text="Developed by: www.facebook.com/appsmakerbd" />
        </div>
    );
});

export default ComponentToPrintTable;
