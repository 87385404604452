import {
    faHome,
    faPrint,
    faSpinner,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { UserContext } from "../../App";
import { baseApiUrl, guestRoute } from "../../config";
import TopNavigation from "../Shared/TopNavigation/TopNavigation";
import ComponentToPrint from "./ComponentToPrint";

const GuestDetails = () => {
    const [globalState, setGlobalState] = useContext(UserContext);
    const componentRef = useRef();
    const { singleGuest, isLoading, isError, errorMessage, userInfo } =
        globalState;
    const { _id, name, address, createdAt, phone } = singleGuest || {};
    const { id } = useParams();

    const fetchSingleGuest = async (id) => {
        try {
            const response = await axios.get(`${baseApiUrl}${guestRoute}/${id}`);
            console.log(response, "single guest response");
            if (response?.data?.data) {
                setGlobalState({
                    ...globalState,
                    singleGuest: response.data.data,
                    isLoading: false,
                    isError: false,
                });
            } else {
                setGlobalState({
                    ...globalState,
                    singleGuest: {},
                    isLoading: false,
                    isError: false,
                });
            }
            return response;
        } catch (error) {
            setGlobalState({
                ...globalState,
                singleGuest: {},
                isLoading: false,
                isError: true,
                errorMessage: error.message,
            });
            console.log(error, "error");
            return error;
        }
    };

    useEffect(() => {
        fetchSingleGuest(id)
            .then((res) => {
                console.log(res, "response inside use effect");
            })
            .catch((err) => {
                console.log(err, "Error inside useEffect");
            });
    }, [id]);
    let content = null;
    if (isLoading) {
        content = (
            <div className="bg-white p-4 rounded-3 text-center">
                <h2 className="text-primary">
                    <FontAwesomeIcon icon={faSpinner} className="spinner" /> Loading...
                </h2>
            </div>
        );
    } else if (isError) {
        content = (
            <div className="bg-white p-4 rounded-3 text-center">
                <p className="text-primary">
                    <FontAwesomeIcon icon={faSpinner} className="spinner" /> ERROR!{" "}
                    {errorMessage}{" "}
                </p>
            </div>
        );
    } else if (singleGuest.hasOwnProperty("name") && !isError) {
        const formattedDate = moment(createdAt).format("DD/MM/YYYY  hh:mm:ss A");
        const qrValue = `http://open-door.appsmakerbd.com/guest/${_id}`;
        content = (
            <div className="bg-white p-4 rounded-3 text-center">
                <ComponentToPrint
                    ref={componentRef}
                    item={{ ...singleGuest, qrValue, formattedDate }}
                />

                <Link to="/" className="btn btn-outline-dark  mt-3 mx-1">
                    <FontAwesomeIcon icon={faHome} /> Go Back Home
                </Link>
                {userInfo?.uid && (
                    <Link to="/guests" className="btn btn-primary  mt-3 mx-1">
                        <FontAwesomeIcon icon={faUsers} /> All Guests
                    </Link>
                )}

                <ReactToPrint
                    trigger={() => (
                        <button className="btn btn-outline-success  mt-3 mx-1">
                            <FontAwesomeIcon icon={faPrint} /> Print
                        </button>
                    )}
                    content={() => componentRef.current}
                    documentTitle={`guest-${name}-${phone}-on-${moment().format("DD/MM/YYYY")}`}
                />
            </div>
        );
    } else if (isLoading && !isError) {
        content = (
            <div className="bg-white p-4 rounded-3 text-center">
                <p className="text-warning"> No Guest Found! </p>
            </div>
        );
    }
    return (
        <div className="container-area d-block h-100">
            <TopNavigation />
            <Container>{content}</Container>
        </div>
    );
};

export default GuestDetails;
