import React, { createContext, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

import Checkout from "./component/Checkout/Checkout";
import AddPortfolio from "./component/Dashboard/AddPortfolio/AddPortfolio";
import AddWebService from "./component/Dashboard/AddWebService/AddWebService";
import AddReview from "./component/Dashboard/Dashboard/AddReview/AddReview";
import Dashboard from "./component/Dashboard/Dashboard/Dashboard";
import MakeAdmin from "./component/Dashboard/MakeAdmin/MakeAdmin";
import ManageServices from "./component/Dashboard/ManageServices/ManageServices";
import Orders from "./component/Dashboard/Orders/Orders";
import Guest from "./component/guestPage/Guest";
import GuestDetails from "./component/guestPage/GuestDetails";
import Home from "./component/HomePage/Home/Home";
import Login from "./component/Shared/Login/Login";
import NotFound from "./component/Shared/NotFound/NotFound";
import PrivateRoute from "./component/Shared/PrivateRoute/PrivateRoute";

export const UserContext = createContext();

//Passing fonts using Library

function App() {
  const initialGlobalState = {
    userInfo: {},
    singleGuest: {},
    allGuest: [],
    isLoading: false,
    isError: false,
    errorMessage: ""

  }
  const [globalState, setGlobalState] = useState(initialGlobalState);

  return (
    <UserContext.Provider value={[globalState, setGlobalState]}>
      <Router>
        {/* <Header></Header> */}
        <Switch>
          <Route exact path="/">
            <Home></Home>
          </Route>

          <Route path="/home">
            <Home></Home>
          </Route>
          <Route path="/guest/:id">
            <GuestDetails />
          </Route>

          <Route path="/login">
            <Login></Login>
          </Route>
          <PrivateRoute exact path="/guests">
            <Guest />
          </PrivateRoute>
          <PrivateRoute exact path="/checkout/:id">
            <Checkout></Checkout>
          </PrivateRoute>

          <PrivateRoute path="/dashboard/orderList">
            <Orders></Orders>
          </PrivateRoute>

          <PrivateRoute exact path="/dashboard">
            <Dashboard></Dashboard>
          </PrivateRoute>

          <PrivateRoute path="/dashboard/addWebService">
            <AddWebService></AddWebService>
          </PrivateRoute>

          <PrivateRoute path="/dashboard/addPortfolio">
            <AddPortfolio></AddPortfolio>
          </PrivateRoute>

          <PrivateRoute path="/dashboard/makeAdmin">
            <MakeAdmin></MakeAdmin>
          </PrivateRoute>

          <PrivateRoute path="/dashboard/addReview">
            <AddReview></AddReview>
          </PrivateRoute>

          <PrivateRoute path="/dashboard/manageServices">
            <ManageServices></ManageServices>
          </PrivateRoute>

          <Route path="/*">
            <NotFound></NotFound>
          </Route>
        </Switch>

      </Router>
    </UserContext.Provider>
  );
}

export default App;
