import React from "react";

const FooterText = ({ text }) => {
    return (
        <p className="text-center text-secondary">
            <small>
                {text}
                <a href="https://facebook.com/appsmakerbd" target="_blank">
                    (Visit/Order)
                </a>
            </small>
        </p>
    );
};

export default FooterText;
