import {
    faCheck,
    faDollar,
    faMapMarkerAlt,
    faMobile,
    faSpinner,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { debounce } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../../App";
import { baseApiUrl, guestRoute } from "../../config";

const GuestRegistrationModal = ({ show, handleClose }) => {
    const [globalState, setGlobalState] = useContext(UserContext);
    const initialFormData = {
        name: "",
        phone: "",
        address: "",
        amount: 0,
    };
    const [formData, setFormData] = useState({ ...initialFormData });
    const addGuest = async (payload) => {
        setGlobalState({
            ...globalState,
            isLoading: true,
        });
        try {
            const response = await axios.post(baseApiUrl + guestRoute, payload);
            console.log(response, "Guest add response");
            if (response.data && !response.error) {
                toast("Guest Added Successfully");
                setGlobalState({
                    ...globalState,
                    singleGuest: response?.data?.data,
                    isLoading: false,
                });
                setFormData(initialFormData);
                handleClose();
            }
        } catch (error) {
            console.log(error, "API error");
        }
    };
    const handleInputs = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const submitHandler = (formData) => {
        const { name, phone, address } = formData;

        if (name && phone && address) {
            addGuest(formData).then((res) => {
                console.log("response", res);
            });
        } else {
            console.log("Fields required");
        }
    };
    const debouncedChangeHandler = useMemo(
        () => debounce(submitHandler, 300),
        []
    );

    return (
        <>
            <ToastContainer />
            <Modal show={show} onHide={handleClose} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>New Guest Registration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group mb-2">
                        <label>
                            <FontAwesomeIcon icon={faUser} /> Name
                        </label>
                        <input
                            onChange={(e) => handleInputs(e)}
                            value={formData.name}
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Guest Name"
                            required
                        />
                    </div>
                    <div className="form-group mb-2">
                        <label>
                            <FontAwesomeIcon icon={faMobile} /> Mobile
                        </label>
                        <input
                            onChange={(e) => handleInputs(e)}
                            value={formData.phone}
                            type="text"
                            className="form-control"
                            name="phone"
                            placeholder="Guest Mobile Number"
                            required
                        />
                    </div>

                    <div className="form-group mb-2">
                        <label>
                            <FontAwesomeIcon icon={faMapMarkerAlt} /> Address
                        </label>
                        <input
                            onChange={(e) => handleInputs(e)}
                            value={formData.address}
                            type="text"
                            className="form-control"
                            name="address"
                            placeholder="Guest Address"
                            required
                        />
                    </div>

                    <div className="form-group mb-2">
                        <label>
                            <FontAwesomeIcon icon={faDollar} /> Received Amount (BDT)
                        </label>
                        <input
                            onChange={(e) => handleInputs(e)}
                            value={formData.amount}
                            type="number"
                            className="form-control"
                            name="amount"
                            placeholder="Received Amount"
                            required
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => debouncedChangeHandler(formData)}
                        disabled={!(formData.name && formData.phone && formData.address)}
                    >
                        {globalState.isLoading ? (
                            <>
                                <FontAwesomeIcon icon={faSpinner} className="spinner px-2" />{" "}
                                Please Wait...{" "}
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faCheck} className="px-2" /> Save Guest
                            </>
                        )}
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default GuestRegistrationModal;
