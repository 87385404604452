import React from 'react'

const FixedBottom = ({ children }) => {
    return (
        <div className="fixed-bottom">
            {children}
        </div>
    )
}

export default FixedBottom