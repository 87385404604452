import {
  faHome,
  faSpinner,
  faUnlock,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { UserContext } from "../../../App";
import "./Login.css";
import {
  createUserWithEmailAndPassword,
  handleFbSignIn,
  handleGoogleSignIn,
  handleSignOut,
  initializeLoginFramework,
  signInWithEmailAndPassword
} from "./LoginManager";

function Login() {
  initializeLoginFramework();
  const [loginError, setLoginError] = useState("");
  const [newUser, setNewUser] = useState(false);
  const [userInfo, setUserInfo] = useState({
    isSignedIn: false,
    name: "",
    email: "",
    password: "",
    photo: "",
    error: "",
    success: "",
  });

  //Importing UserContext variable  from App.js  into useContext() hook here
  const [globalState, setGlobalState] = useContext(UserContext);

  //For redirecting user to Where he came from
  const history = useHistory();
  const location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };

  //Google Sign in
  const googleSignIn = () => {
    handleGoogleSignIn().then((res) => shortenResponse(res, true));
  };

  //Facebook Sign In
  const fbSignIn = () => {
    handleFbSignIn().then((res) => shortenResponse(res, true));
  };

  //Sign Out
  const signOut = () => {
    handleSignOut().then((res) => shortenResponse(res, false));
  };

  //Custom Sign in area

  //Error Handling using Regular expression
  const handleBlur = (event) => {
    let isFormValidate = true;
    const targetName = event.target.name;
    const targetValue = event.target.value;

    //console.log(event.target.name,event.target.value)

    if (targetName === "email") {
      const regExp = /\S+@\S+\.\S+/;
      const isEmailValidate = regExp.test(targetValue);
      const isFormValidate = isEmailValidate;
      !isEmailValidate
        ? setLoginError("Please provide valid email")
        : setLoginError("");
      //console.log(isEmailValidate)
    }

    if (targetName === "password") {
      const pass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
      const passHasNumber = pass.test(targetValue);
      const isFormValidate = passHasNumber;
      //console.log(isFormValidate+'only pass'+passHasNumber);
      !passHasNumber &&
        setLoginError(
          "Password should be numeric digit, one uppercase and one lowercase letter"
        );
      //console.log(isPassValidate)
    }

    if (targetName === "repassword") {
      const pass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
      const passHasNumber = pass.test(targetValue);
      const isFormValidate = passHasNumber;
      const password = userInfo.password;
      if (!passHasNumber || password !== targetValue) {
        setLoginError(
          "Password and Confirm Password should be same & must be contain numeric digit, one uppercase and one lowercase letter"
        );
      } else {
        setLoginError("");
      }
    }

    if (isFormValidate) {
      //copying object data from state
      const newUserInfo = { ...userInfo };
      //assigning new value
      newUserInfo[targetName] = targetValue;
      console.log("---------------");
      console.log(targetName);
      setUserInfo(newUserInfo);
    } else {
      const newUserInfoFalse = { ...userInfo };
      newUserInfoFalse[targetName] = "";
      setUserInfo(newUserInfoFalse);
    }
  };
  //End handleblur

  //Submitting the form and inserting into firebase
  const handleSubmit = (event) => {
    setGlobalState({
      ...globalState,
      isLoading: true,
    });
    event.preventDefault();
    //console.log('submitted');

    //Sign up
    if (
      newUser &&
      userInfo.email &&
      userInfo.password &&
      loginError.length === 0
    ) {
      console.log("signup" + loginError.length);
      //console.log(userInfo.name+ 'submitted new user');
      createUserWithEmailAndPassword(
        userInfo.name,
        userInfo.email,
        userInfo.password
      ).then((res) => shortenResponse(res, true));
    }

    //Sign in
    if (!newUser && userInfo.email && userInfo.password) {
      console.log("submitted existing users");

      signInWithEmailAndPassword(userInfo.email, userInfo.password).then(
        (res) => shortenResponse(res, true)
      );
    }
  };

  const shortenResponse = (res, redirectAction) => {
    console.log(res, "Inside Shorten response");

    if (res.error) {
      setLoginError(res.error);
      setGlobalState({
        ...globalState,
        userInfo: {},
        isError: true,
        errorMessage: "Login Error Occured, Please check Internet Connection",
        isLoading: false,
      });
    } else {
      setLoginError("");
      setUserInfo(res);
      setGlobalState({
        ...globalState,
        userInfo: res,
        isError: true,
        errorMessage: "",
        isLoading: false,
      });
      redirectAction && history.replace(from);
    }
  };

  return (
    <div
      className="container-fluid login-area  d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div className=" col-lg-3 col-md-3 col-sm-6 col-xs-12 mx-auto login-box">
        <div className="customAuth">
          {userInfo.error && <p style={{ color: "red" }}> {userInfo.error}</p>}
          {newUser ? (
            <h2>Create New Account</h2>
          ) : (
            <h2>
              <FontAwesomeIcon icon={faUser} /> Guest Manager Login
            </h2>
          )}
          {loginError && (
            <p style={{ color: "red", fontSize: "1.2rem" }}>{loginError}</p>
          )}

          <form onSubmit={handleSubmit}>
            {newUser && (
              <div className="form-group">
                <input
                  onBlur={handleBlur}
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Name"
                  required
                />
              </div>
            )}

            <div className="form-group">
              <input
                onBlur={handleBlur}
                type="email"
                id="password"
                className="form-control"
                name="email"
                placeholder="Manager E-mail"
                required
              />
            </div>
            <div className="form-group">
              <input
                onBlur={handleBlur}
                type="password"
                id="repassword"
                className="form-control"
                name="password"
                placeholder="Password"
                required
              />
            </div>
            {newUser && (
              <div className="form-group">
                <input
                  onBlur={handleBlur}
                  type="password"
                  className="form-control"
                  name="repassword"
                  placeholder="Confirm Password"
                  required
                />
              </div>
            )}

            {/* {!newUser && <RememberMe></RememberMe>} */}

            {/* Button Area start */}
            <div className="form-group form-buttons-area my-5">
              {newUser ? (
                <>
                  <button className="btn btn-lg bg-theme" type="submit">
                    Sign Up
                  </button>
                  <p>
                    Already have an account?{" "}
                    <span onClick={() => setNewUser(false)}>Login</span>
                  </p>
                </>
              ) : (
                <>
                  <Button
                    className="btn-lg btn-block rounded bg-theme"
                    type="submit"
                  >
                    {globalState.isLoading ? (
                      <>
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="spinner px-2"
                        />{" "}
                        Please wait...
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faUnlock} className="px-2" />{" "}
                        Sign In
                      </>
                    )}
                  </Button>
                  {/* <p>
                    Don't have an account?{" "}
                    <span onClick={() => setNewUser(true)}>
                      Create an Account
                    </span>
                  </p> */}
                  <div className="clearfix"></div>
                  <Link to="/" className="btn  mt-3">
                    <FontAwesomeIcon icon={faHome} /> Go Back home
                  </Link>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const RememberMe = () => {
  return (
    <div className="remember-me d-flex justify-content-between">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          Remember Me
        </label>
      </div>
      <Link to="#">Forgot Password?</Link>
    </div>
  );
};

export default Login;
