import { faPrint, faSpinner, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { UserContext } from "../../App";
import { baseApiUrl, guestRoute } from "../../config";
import TopNavigation from "../Shared/TopNavigation/TopNavigation";
import ComponentToPrintTable from "./ComponentToPrintTable";
import GuestRegistrationModal from "./GuestRegistrationModal";
const Guest = () => {
    const [globalState, setGlobalState] = useContext(UserContext);
    const { singleGuest, allGuest, isLoading, isError, errorMessage } =
        globalState;
    const componentRef = useRef();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const fetchAllUsers = async () => {
        try {
            const response = await axios.get(baseApiUrl + guestRoute);
            if (response?.data?.data.length > 0) {
                setGlobalState({
                    ...globalState,
                    allGuest: response.data.data,
                    isLoading: false,
                    isError: false,
                });
            } else {
                setGlobalState({
                    ...globalState,
                    allGuest: [],
                    isLoading: false,
                    isError: false,
                });
            }
            return response;
        } catch (error) {
            setGlobalState({
                ...globalState,
                allGuest: [],
                isLoading: false,
                isError: true,
                errorMessage: error.message,
            });
            return error;
        }
    };
    useEffect(() => {
        setGlobalState({
            ...globalState,
            isLoading: true,
            isError: false,
            errorMessage: "",
        });
        fetchAllUsers()
            .then((res) => { })
            .catch((err) => {
                console.log("Promise break");
            });
        handleClose()
    }, [singleGuest]);
    const [show, setShow] = useState(false);



    let headerContent = null;
    let tableContent = null;

    if (isLoading) {
        headerContent = (
            <div className="bg-white p-4 py-2 my-3 rounded d-flex justify-content-between ">
                <h2 className="text-primary">
                    <FontAwesomeIcon icon={faSpinner} className="spinner" /> Loading...
                </h2>
            </div>
        );
        tableContent = (
            <div className="bg-white p-4 rounded-3 text-center">
                <h2 className="text-primary">
                    <FontAwesomeIcon icon={faSpinner} className="spinner" /> Loading...
                </h2>
            </div>
        );
    } else if (isError) {
        headerContent = (
            <div className="bg-white p-4 py-2 my-3 rounded d-flex justify-content-between ">
                <h2 className="text-danger">ERROR!!</h2>
                <Button variant="primary" onClick={handleShow}>
                    <FontAwesomeIcon icon={faUser} /> New Guest Registration
                </Button>
            </div>
        );
        tableContent = (
            <div className="bg-white p-4 rounded-3 text-center">
                <p className="text-primary">
                    <FontAwesomeIcon icon={faSpinner} className="spinner" /> ERROR!{" "}
                    {errorMessage}{" "}
                </p>
            </div>
        );
    } else if (allGuest.length > 0 && !isError) {
        headerContent = (
            <div className="bg-white p-4 py-2 my-3 rounded d-flex justify-content-between ">
                <h2 className="text-primary">Total Guest {allGuest.length}</h2>
                <div>
                    <Button variant="primary" className="mx-2" onClick={handleShow}>
                        <FontAwesomeIcon icon={faUser} /> New Registration
                    </Button>
                    <ReactToPrint
                        trigger={() => <Button variant="secondary"><FontAwesomeIcon icon={faPrint} /> Print All</Button>}
                        content={() => componentRef.current}
                        documentTitle={`All-guests-downloaded-on-${moment().format("DD/MM/YYYY")}`}
                    />

                </div>


            </div>
        );
        tableContent = (
            <div className="bg-white p-4 rounded-3 text-center">
                <ComponentToPrintTable ref={componentRef} allGuest={allGuest} />
            </div>
        );
    } else if (allGuest.length === 0 && !isError) {
        headerContent = (
            <div className="bg-white p-4 py-2 my-3 rounded d-flex justify-content-between ">
                <h2 className="text-warning">No Guest Found!</h2>
                <Button variant="primary" onClick={handleShow}>
                    <FontAwesomeIcon icon={faUser} /> New Guest Registration
                </Button>
            </div>
        );
        tableContent = (
            <div className="bg-white p-4 rounded-3 text-center">
                <p className="text-warning"> No Guest Found! </p>
            </div>
        );
    }
    return (
        <>
            <GuestRegistrationModal show={show} handleClose={handleClose} />
            <div className="container-area ">
                <TopNavigation />

                <Container>
                    {headerContent}
                    {tableContent}
                </Container>
            </div>
        </>

    );
};

export default Guest;
