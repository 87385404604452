import React from "react";
import { Link } from "react-router-dom";


import "./NotFound.css";

const NotFound = () => {
    return (
        <div className="not-found-area container">
            <h1>404 Not Found</h1>
            <p>Bad URL. 404 page not found. Please follow the Links</p>
            <Link className="btn btn-success" to="/">
                Go Home
            </Link>
        </div>
    );
};

export default NotFound;
